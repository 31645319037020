<template>
  <div>

    <b-card>
      <b-row>
        <b-col>
          <h3>{{ $store.state.students.studentStats ? $store.state.students.studentStats.full_name : 'Aluno Carregando' }}</h3>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col md="4">
          <label
            for="datepicker-start"
          >Data de início</label>
          <b-button
            v-if="$store.state.students.startDate"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{$store.state.students.startDate = ''; filterEvents();}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-start"
            v-model="$store.state.students.startDate"
            class="form-control"
            :config="config"
            @on-change="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="datepicker-end"
          >Data de fim</label>
          <b-button
            v-if="$store.state.students.endDate"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{$store.state.students.endDate = ''; filterEvents();}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-end"
            v-model="$store.state.students.endDate"
            class="form-control"
            :config="config"
            @on-change="filterEvents"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="loaded"
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          md="12"
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              {{ $store.state.students.studentStats.total_events }} Aulas no periodo
            </h4>
          </div>

          <vue-apex-charts
            type="heatmap"
            height="350"
            :options="student.chartOptions"
            :series="$store.state.students.studentStats.heatmap"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="loaded"
    >
      <b-row class="mx-0">
        <b-col
          md="4"
        >
          <div class="d-sm-flex justify-content-between align-items-center">
            <h4 class="card-title mb-05 text-success">
              Professores
            </h4>
          </div>
          <ul
            class="mb-3"
          >
            <li
              v-for="prof in $store.state.students.studentStats.professors"
              :key="prof.professor__professorprofile__nome"
            >
              <b>{{ prof.professor__professorprofile__nome }}</b> : {{ prof["0"] }}
            </li>
          </ul>

        </b-col>
        <b-col
          md="4"
        >
          <div class="d-sm-flex justify-content-between align-items-center">
            <h4 class="card-title mb-05 text-primary">
              Modalidades
            </h4>
          </div>
          <ul
            class="mb-3"
          >
            <li
              v-for="prof in $store.state.students.studentStats.modality"
              :key="prof.modality__name"
            >
              <b>{{ prof.modality__name }}</b> : {{ prof["0"] }}
            </li>
          </ul>
        </b-col>
        <b-col
          md="4"
        >
          <div class="d-sm-flex justify-content-between align-items-center">
            <h4 class="card-title mb-05">
              Dias da semana
            </h4>
          </div>
          <ul>
            <li
              v-for="prof in $store.state.students.studentStats.day_of_week"
              :key="prof.day_of_week"
            >
              <b>{{ prof.day_of_week }}</b> : {{ prof["0"] }}
            </li>
          </ul>
        </b-col>
      </b-row>

    </b-card>

    <b-card
      v-if="$store.state.students.studentStats"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title">
            Ultimas de aulas
          </h4>
        </b-col>

      </b-row>
      <app-timeline
        v-if="$store.state.students.studentStats"
      >

        <app-timeline-item
          v-for="event in $store.state.students.studentStats.last_events"
          :key="event.id"
          variant="primary"
          icon="UserIcon"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>
              <b-link
                :to="{ name: 'event-edit', params: { id: event.id } }"
                class="aula-link"
              >
                Aula de <span class="modality-name">{{ event.modality__name }}</span> com <span class="professor-name">{{ event.professor__professorprofile__nome }}</span>
              </b-link>
            </h6>
            <small class="text-muted">{{ dateFormater.format(new Date(event.date_time)) }}</small>
          </div>
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <div />
            <b-badge
              pill
              variant="light-primary"
            >
              {{ event.local__clientprofile__name }}
            </b-badge>
          </div>

        </app-timeline-item>

      </app-timeline>
      <div class="d-flex justify-content-between flex-wrap">
        <div />
      </div>

    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      config: {
        allowInput: false,
        locale: Portuguese,
      },
      loaded: false,
      student: {
        chartOptions: {
          chart: {
            height: 350,
            type: 'heatmap',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#006CB5'],
          title: {
            text: 'Aulas ultimo ano',
          },
        },

      },
    }
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      year: 'numeric',
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: '2-digit',
      weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    })
    this.$store.commit('students/clearStudentStats')
    this.$store.dispatch('students/getStudentStats', this.userId).then(() => {
      this.loaded = true
    })
  },
  methods: {
    filterEvents() {
      this.loaded = false
      this.$store.commit('students/clearStudentStats')
      this.$store.dispatch('students/getStudentStats', this.userId).then(() => {
        this.loaded = true
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~flatpickr/dist/themes/airbnb.css";
.form-control[readonly] {
  background-color: inherit;
}

.aula-link {
  color: black;
  cursor: pointer;
  font-weight: 300;
  font-size: large;
}
.modality-name{
  color: $primary;
  font-weight: 500;
}
.professor-name{
  color: $success;
  font-weight: 500;
}
ul{
  list-style: none;
  padding: 0;
}
</style>
